import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { SnackbarProvider } from 'notistack';
import { CONFIG } from 'src/config';
import Home from 'src/modules/Home';
import AppWithOktaAuth from 'src/modules/OktaAuth/AppWithOktaAuth';
import './app.css';
import AnalyticsComponent from './modules/Firebase/PageAnalytics';

const App = () => {
  return (
    <BrowserRouter>
      <AnalyticsComponent />
      <SnackbarProvider
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
        autoHideDuration={1500}
        preventDuplicate={true}>
        {CONFIG.USE_OKTA === 'true' ? <AppWithOktaAuth /> : <Home />}
      </SnackbarProvider>
    </BrowserRouter>
  );
};

export default App;
