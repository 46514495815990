import React, { useContext } from 'react';
import { Redirect, Route } from 'react-router-dom';
import { AUTH_STATUS, ROUTES } from 'src/constants';
import { UserAuthContext } from 'src/modules/Home';

function PrivateRoute({ component: Component, ...restOfProps }) {
  const { authStatus } = useContext(UserAuthContext);
  return (
    <Route
      {...restOfProps}
      render={(props) =>
        authStatus === AUTH_STATUS.authenticated ? (
          <Component {...props} />
        ) : (
          <Redirect to={ROUTES.unauthenticated} />
        )
      }
    />
  );
}

export default PrivateRoute;
