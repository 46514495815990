import { Box } from '@mui/material';
import { useEffect, useState } from 'react';
import useAxios from 'src/hooks/useAxios';
import { getPartnerUploadHistory } from './api';
import { PAGE_SIZE } from './constant';
import UploadComponents from './upload';
import UploadHistory from './uploadHistory';

let maxPageGenerated: any = 1;

const PartnerUploads = () => {
  const [currentPage, setCurrentPage] = useState(0);
  const [uploadHistoryData, setUploadHistoryData] = useState([]);
  const [pageCount, setPageCount] = useState(1);

  const axiosInstance = useAxios();

  const fetchUploadHistoryAfterUploadFile = async () => {
    setCurrentPage(0);
    setPageCount(1);
    maxPageGenerated = 1;
  };

  const fetchUploadHistoryList = async (page = currentPage) => {
    const response = await getPartnerUploadHistory(axiosInstance, page);
    if (response?.data?.length) {
      setUploadHistoryData(response?.data);
      if (
        response.data?.length === PAGE_SIZE &&
        currentPage >= maxPageGenerated - 1
      ) {
        setPageCount((pageCount) => pageCount + 1);
      }
    }
  };

  useEffect(() => {
    fetchUploadHistoryList();
  }, [currentPage]);

  const onPageChange = (page) => {
    if (page > maxPageGenerated) {
      maxPageGenerated = page;
    }
    setCurrentPage(page - 1);
  };
  return (
    <Box>
      <UploadComponents
        fetchUploadHistoryAfterUploadFile={fetchUploadHistoryAfterUploadFile}
      />
      <UploadHistory
        uploadHistoryData={uploadHistoryData}
        onPageChange={onPageChange}
        pageCount={pageCount}
        currentPage={currentPage}
      />
    </Box>
  );
};

export default PartnerUploads;
