import * as React from 'react';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { Button, Typography } from '@mui/material';
import {
  PARTNER_PAYMENT_HEADERS,
  PAYMENT_BREAKDOWN_HEADERS
} from '../constants';
import { getPaymentBreakdown } from './actions';
import useAxios from 'src/hooks/useAxios';
import CustomPagination from 'src/components/Pagination';
import { PRIMARY_COLOR } from 'src/constants';

function Row(props) {
  const { row, selectedRole, paymentCycleId } = props;
  const [paymentBreakdownList, setPaymentBreakdownList] = React.useState([]);
  /* eslint-disable no-unused-vars */
  const [open, setOpen] = React.useState<any>([]); // needed so that state is updated and collapse can work
  const API = useAxios();

  React.useEffect(() => {
    localStorage.setItem('expandedPayments', JSON.stringify([]));
  }, []);

  const getExpandedPaymentsList = () => {
    const expandedPaymentsList: any = localStorage.getItem('expandedPayments');
    return JSON.parse(expandedPaymentsList);
  };

  const handleCollapse = (partnerId: any) => {
    const expandedPaymentsPartnerIds: any = getExpandedPaymentsList();
    const filteredList = expandedPaymentsPartnerIds.filter(
      (elt) => elt !== partnerId
    );
    setOpen(filteredList);
    localStorage.setItem('expandedPayments', JSON.stringify(filteredList));
  };

  const handleExpand = async (partnerId: any) => {
    if (localStorage.getItem('expandedPayments') === null) {
      localStorage.setItem('expandedPayments', JSON.stringify([]));
    }
    const expandedPaymentsPartnerIds: any = getExpandedPaymentsList();
    expandedPaymentsPartnerIds.push(partnerId);
    localStorage.setItem(
      'expandedPayments',
      JSON.stringify(expandedPaymentsPartnerIds)
    );
    setOpen(expandedPaymentsPartnerIds);
    try {
      const res = await getPaymentBreakdown(
        API,
        selectedRole,
        expandedPaymentsPartnerIds,
        paymentCycleId
      );
      if (res && res.data && res.data.dataList) {
        setPaymentBreakdownList(res.data.dataList);
      }
    } catch (err) {
      // empty
    }
  };

  const handlePaymentBreakdownData = (partnerId: any) => {
    const breakdownData = paymentBreakdownList
      .filter((breakdown: any) => breakdown.partnerId === partnerId)
      .map((elt: any) => elt.breakdownDetailDTOList);
    return breakdownData.length ? breakdownData[0] : [];
  };

  const isExpanded = (partnerId: any) => {
    const expandedPaymentsPartnerIds: any = getExpandedPaymentsList();
    if (
      expandedPaymentsPartnerIds &&
      expandedPaymentsPartnerIds.length &&
      expandedPaymentsPartnerIds.indexOf(partnerId) >= 0
    ) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <React.Fragment>
      <TableRow>
        {PARTNER_PAYMENT_HEADERS.map((header) => (
          <TableCell
            scope="row"
            sx={{ color: 'rgba(0, 0, 0, 0.70)' }}
            align={
              header.key === 'totalAmountExTax' ||
              header.key === 'totalAmountInclTax'
                ? 'center'
                : 'left'
            }
            key={header.key}>
            {header.key === 'pancard' ? 'XXXX' : row[header.key]}
          </TableCell>
        ))}
        <TableCell>
          <Button
            color="primary"
            sx={{
              borderRadius: '10px',
              marginLeft: '30px',
              textTransform: 'capitalize',
              color: PRIMARY_COLOR
            }}
            onClick={() => {
              isExpanded(row.partnerId)
                ? handleCollapse(row.partnerId)
                : handleExpand(row.partnerId);
            }}
            startIcon={
              isExpanded(row.partnerId) ? (
                <KeyboardArrowUpIcon />
              ) : (
                <KeyboardArrowDownIcon />
              )
            }>
            {isExpanded(row.partnerId) ? 'Collapse' : 'Expand'}
          </Button>
        </TableCell>
      </TableRow>
      {isExpanded(row.partnerId) && (
        <TableRow>
          <TableCell
            sx={{
              padding: '30px 0',
              borderBottom: 'none',
              position: 'relative',
              left: '40%',
              transform: 'translateX(-40%)'
            }}
            colSpan={6}>
            <Collapse
              in={isExpanded(row.partnerId)}
              timeout="auto"
              unmountOnExit>
              <Box>
                <Table size="small" aria-label="purchases">
                  <TableHead>
                    <TableRow>
                      {PAYMENT_BREAKDOWN_HEADERS.map((header, index) => (
                        <TableCell
                          key={`${index}-${header.key}`}
                          sx={{ fontWeight: '700', opacity: '0.9' }}>
                          {header.label}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {isExpanded(row.partnerId) &&
                      handlePaymentBreakdownData(row.partnerId).length &&
                      handlePaymentBreakdownData(row.partnerId).map((data) => (
                        <TableRow key={data.date}>
                          {PAYMENT_BREAKDOWN_HEADERS.map((header, index) => (
                            <TableCell
                              component="th"
                              key={`${index}-${header.key}`}
                              scope="row"
                              sx={{ color: 'rgba(0, 0, 0, 0.70)' }}>
                              {data[header.key] || '-'}
                            </TableCell>
                          ))}
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      )}
    </React.Fragment>
  );
}

export default function PartnerPaymentList({
  tableData,
  selectedRole,
  paymentCycleId,
  page,
  setPage,
  fetchTableData,
  pageCount,
  setSearchText
}) {
  const onPageChange = (page) => {
    setPage(page);
    setSearchText('');
    fetchTableData(page);
  };

  return (
    <>
      <TableContainer component={Paper}>
        <Table aria-label="collapsible table">
          <TableHead>
            <TableRow>
              {PARTNER_PAYMENT_HEADERS.map((header, index) => (
                <TableCell
                  sx={{ fontWeight: '700', opacity: '0.9' }}
                  key={`${header.key}-${index}`}>
                  {header.label}
                </TableCell>
              ))}
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {!!tableData.length &&
              tableData.map((row: any) => (
                <Row
                  key={row.name}
                  row={row}
                  selectedRole={selectedRole}
                  paymentCycleId={paymentCycleId}
                />
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      {!tableData.length && (
        <Typography
          sx={{ margin: '30px 0px', textAlign: 'center', fontSize: '18px' }}>
          No Data Available
        </Typography>
      )}
      {pageCount >= 0 && (
        <CustomPagination
          page={page}
          setPage={onPageChange}
          pageCount={pageCount}
          dataLength={tableData.length}
        />
      )}
    </>
  );
}
