import React, { ChangeEventHandler, FocusEventHandler } from 'react';
import { MenuItem, TextField } from '@mui/material';
import styled from '@emotion/styled';

interface textFieldProps {
  id?: string;
  label: string;
  name: string;
  required: boolean;
  helperText?: string | undefined;
  error: any;
  value: string | null;
  type: string;
  disabled?: boolean;
  onBlur?: FocusEventHandler;
  InputLabelProps?: {
    shrink: boolean;
  };
  inputProps?: {
    min?: string;
    maxLength?: number;
  };
  onChange?: ChangeEventHandler;
  sx?: any;
  multiline?: boolean;
  maxRows?: number;
  minRows?: number;
  select?: boolean;
  menuItems?: any;
}

const CustomTextField = (props: textFieldProps) => {
  const {
    id,
    label,
    name,
    required,
    helperText,
    error,
    type,
    InputLabelProps,
    inputProps,
    onBlur,
    onChange,
    sx,
    value,
    disabled,
    multiline,
    maxRows,
    minRows,
    select,
    menuItems
  } = props;
  return (
    <>
      <StyledTextField
        id={id}
        label={label}
        name={name}
        required={required}
        error={error}
        helperText={helperText}
        type={type}
        value={value}
        size="small"
        variant="outlined"
        InputLabelProps={InputLabelProps}
        inputProps={inputProps}
        onChange={onChange}
        onBlur={onBlur}
        sx={sx}
        disabled={disabled}
        FormHelperTextProps={{ style: { color: '#C31515' } }}
        multiline={multiline}
        maxRows={maxRows}
        minRows={minRows}
        select={select}>
        <MenuItem key={'select'} value={''}>
          Select
        </MenuItem>
        {menuItems?.length &&
          menuItems.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
      </StyledTextField>
    </>
  );
};

export default CustomTextField;

const StyledTextField = styled(TextField)`
  label.Mui-focused {
    color: ${(props) => (props.error ? '#C31515' : '#4003a6')};
  }
  .MuiOutlinedInput-root {
    &.Mui-focused fieldset {
      border-color: ${(props) => (props.error ? '#C31515' : '#4003a6')};
    }
  }
  .MuiOutlinedInput-input {
    cursor: ${(props) => (props.type === 'date' ? 'pointer' : '')};
  }
  .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: ${(props) => (props.error ? '#C31515' : '#4003a6')};
  }
  .MuiOutlinedInput-root {
    &:hover fieldset {
      cursor: pointer;
    }
  }
`;
