import { useEffect, useRef, useState } from 'react';
import { Download, Upload } from '@mui/icons-material';
import { Box, Button, Link } from '@mui/material';
import CustomDropdown from 'src/components/CustomDropdown';
import CustomButton from 'src/components/CustomButton';
import { bulkUploadFile, getBulkTypeStatus } from './api';
import useAxios from 'src/hooks/useAxios';
import { useSnackbar } from 'notistack';
import { BORDER_COLOR, PRIMARY_COLOR } from 'src/constants';

const UploadComponents = ({ fetchUploadHistoryAfterUploadFile }) => {
  const [bulkUploadStatusType, setBulkUploadStatusType] = useState<any>([]);
  const [selectedUploadStatus, setSelectedUploadStatus] = useState('');
  const inputRef: any = useRef(null);
  const { enqueueSnackbar } = useSnackbar();

  const axiosInstance = useAxios();

  const onChangeSelectedUploadStatus = (value) => {
    setSelectedUploadStatus(value);
  };

  const createUploadDropdownData = (data) => {
    const dropdownData = data.map((elt: any) => {
      return {
        label: elt?.displayName,
        value: elt?.bulkUploadType
      };
    });
    return dropdownData;
  };

  const fetchUploadStatusList = async () => {
    const response = await getBulkTypeStatus(axiosInstance);
    if (response?.data?.responseDTOList?.length) {
      const defaultValue =
        response?.data?.responseDTOList[0]?.bulkUploadType || '';
      onChangeSelectedUploadStatus(defaultValue);
      setBulkUploadStatusType(response?.data?.responseDTOList);
    }
  };

  useEffect(() => {
    fetchUploadStatusList();
  }, []);

  const getTemplateLink = () => {
    const selectedTemplateLink = bulkUploadStatusType.filter(
      (elt) => elt.bulkUploadType === selectedUploadStatus
    );
    return selectedTemplateLink.length
      ? selectedTemplateLink[0].bulkUploadTemplateURI
      : '';
  };

  const uploadXlsxfile = async (excelData) => {
    const fileUpdateResponse: any = await bulkUploadFile(
      axiosInstance,
      selectedUploadStatus,
      excelData
    );
    if (!fileUpdateResponse) {
      return;
    }
    if (fileUpdateResponse?.errorMap) {
      const mappedError = Object.keys(fileUpdateResponse.errorMap).map(
        (key) => ({
          type: key,
          value: fileUpdateResponse.errorMap[key]
        })
      );
      if (mappedError.length) {
        enqueueSnackbar('Error In Uploaded Details', {
          variant: 'error'
        });
      } else {
        enqueueSnackbar('File Uploaded Sucessfully', {
          variant: 'success'
        });
        fetchUploadHistoryAfterUploadFile();
      }
    } else {
      enqueueSnackbar('File Uploaded Sucessfully', {
        variant: 'success'
      });
      fetchUploadHistoryAfterUploadFile();
    }
  };

  const uploadFile = (event) => {
    const file: File = event.target.files[0];
    let fileType = '';
    if (file && file.name) {
      fileType = file.name.substr(file.name.lastIndexOf('.') + 1);
    }
    if (event.target.files.length !== 0) {
      if (fileType === 'xlsx') {
        let excelData = new FormData();
        if (fileType === 'xlsx') {
          excelData.append('file', file, file.name);
          uploadXlsxfile(excelData);
        }
      } else {
        enqueueSnackbar('Please upload file in xlsx format only', {
          variant: 'error'
        });
      }
    } else {
      enqueueSnackbar('Please attach xlsx format excel file size upto 10 MB', {
        variant: 'error'
      });
    }
  };

  return (
    <Box
      sx={{
        display: 'flex',
        boxShadow: '2px 2px 2px 2px #0000001a',
        mb: 4,
        padding: '20px 10px',
        width: '100%'
      }}>
      <Box sx={{ width: '40%' }}>
        <CustomDropdown
          options={createUploadDropdownData(bulkUploadStatusType)}
          value={selectedUploadStatus}
          onChange={onChangeSelectedUploadStatus}
          label="Upload Type"
          width="90%"
          showDefaultOption={false}
        />
      </Box>
      <input
        type={'file'}
        ref={inputRef}
        style={{ display: 'none' }}
        onChange={uploadFile}
        accept=".xlsx"
      />
      <Button
        color="primary"
        size="medium"
        sx={{
          textTransform: 'capitalize',
          color: PRIMARY_COLOR,
          border: `1px solid ${BORDER_COLOR}`,
          borderRadius: '10px',
          ':hover': {
            background: '#f7f3ff',
            border: `1px solid ${BORDER_COLOR}`
          }
        }}
        variant="outlined"
        startIcon={<Upload />}
        onClick={() => inputRef.current.click()}>
        Upload File
      </Button>
      <Link
        sx={{ marginLeft: 'auto' }}
        href={getTemplateLink()}
        target={'_blank'}
        underline="none">
        <CustomButton
          title="Export Template"
          buttonType="primary"
          variant="contained"
          startIcon={<Download />}
        />
      </Link>
    </Box>
  );
};

export default UploadComponents;
