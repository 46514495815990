// @ts-nocheck
import { CssBaseline } from '@mui/material';
import Box from '@mui/material/Box';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import axios from 'axios';
import { createBrowserHistory } from 'history';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { intersection } from 'lodash';
import {
  BrowserRouter,
  Redirect,
  Route,
  Router,
  Switch
} from 'react-router-dom';
import AppBar from 'src/components/AppBar';
import SimpleBackdrop from 'src/components/Backdrop';
import Drawer from 'src/components/Drawer';
import PrivateRoute from 'src/components/PrivateRoute';
import { CONFIG } from 'src/config';
import {
  AUTH_STATUS,
  DEFAULT_USER_DATA,
  PRIVATE_ROUTES,
  ROUTES,
  UserDataResponseInterface
} from 'src/constants';
import useOktaInstance from 'src/hooks/useOktaInstance';
import Dashboard from 'src/modules/Dashboard';
import PartnerDetails from 'src/modules/PartnerDetails';
import {
  OKTA_VALIDATION_BODY,
  OKTA_VALIDATION_ENDPOINT
} from 'src/modules/PartnerDetails/constants';
import UnAuthenticated from 'src/modules/UnAuthenticated';

export const UserAuthContext = React.createContext<{
  authStatus: string;
  setAuthStatus: any;
}>({
  authStatus: AUTH_STATUS.pending,
  setAuthStatus: () => {}
});
export const UserDataContext = React.createContext<{
  userData: UserDataResponseInterface;
  setUserData: any;
}>({ userData: DEFAULT_USER_DATA, setUserData: () => {} });

const HomeWithRouter = () => {
  const theme = createTheme();
  const browserHistory = createBrowserHistory();
  const [authStatus, setAuthStatus] = useState(
    process.env.REACT_APP_USE_OKTA === 'true'
      ? AUTH_STATUS.pending
      : AUTH_STATUS.authenticated
  );
  const [userData, setUserData] = useState(DEFAULT_USER_DATA);
  const { authToken } = useOktaInstance();
  const [userRoles, setUserRoles] = useState<any>([]);

  const createAuthorityList = (authorities) => {
    const authorityList = authorities.map((access) => {
      return access.authority
    })
    return authorityList;
  };

  useEffect(() => {
    if (CONFIG.USE_OKTA === 'false') {
      setAuthStatus(AUTH_STATUS.authenticated);
    } else if (authToken && CONFIG.USE_OKTA === 'true') {
      axios
        .post(OKTA_VALIDATION_ENDPOINT, OKTA_VALIDATION_BODY, {
          headers: {
            Authorization: `Bearer ${authToken}`,
            'X-Domain': 'NURTURE_FARM'
          }
        })
        .then((resp) => {
          setAuthStatus(AUTH_STATUS.authenticated);
          setUserData(resp?.data);
          const authorityList = createAuthorityList(resp?.data?.authorities);
          setUserRoles(authorityList);
          localStorage.setItem('userRoles', authorityList);
          localStorage.setItem(
            'partnerAccessToken',
            resp.data?.oauthResponseDTO?.access_token
          );
        })
        .catch(() => {
          setAuthStatus(AUTH_STATUS.unauthenticated);
        });
    }
  }, [authToken]);
  return (
    /* tslint:disable */
    <Router history={browserHistory}>
      <ThemeProvider theme={theme}>
        <BrowserRouter basename={ROUTES.appRoot}>
          <UserAuthContext.Provider value={{ authStatus, setAuthStatus }}>
            <UserDataContext.Provider value={{ userData, setUserData }}>
              <Home userRoles={userRoles} />
            </UserDataContext.Provider>
          </UserAuthContext.Provider>
        </BrowserRouter>
      </ThemeProvider>
    </Router>
  );
};

const Home = ({ userRoles }) => {
  const { authStatus } = React.useContext(UserAuthContext);

  const isArrayWithLength = (arr) => {
    return Array.isArray(arr) && arr.length;
  };

  const getAllowedRoutes = () => {
    const allowedRoutes = PRIVATE_ROUTES.filter((route) => {
      const permissions = route.permissions;
      if (!permissions) {
        return true;
      } else if (!isArrayWithLength(permissions)) return true;
      else return intersection(permissions, userRoles).length;
    });
    return allowedRoutes;
  };

  return (
    <Box sx={{ display: 'flex', height: 'calc(100vh - 64px)' }}>
      <CssBaseline />
      <AppBar />
      <Drawer userRoles={userRoles} />
      {authStatus !== AUTH_STATUS.pending && (
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            height: '100vh',
            overflow: 'auto'
          }}>
          <Box
            sx={{
              margin: '100px 20px 20px 20px'
            }}>
            <Switch>
              <PrivateRoute
                exact
                path={ROUTES.partnerDetails}
                component={PartnerDetails}
              />
              <PrivateRoute
                exact
                path={ROUTES.dashboard}
                component={Dashboard}
              />
              {getAllowedRoutes().map((route) => (
                <Route
                  exact
                  key={route.path}
                  path={route.path}
                  component={route.component}
                />
              ))}
              <Route
                path={ROUTES.unauthenticated}
                component={UnAuthenticated}
              />
              <Redirect to={ROUTES.dashboard} />
            </Switch>
          </Box>
        </Box>
      )}
      <SimpleBackdrop
        open={authStatus === AUTH_STATUS.pending}></SimpleBackdrop>
    </Box>
  );
};

export default HomeWithRouter;
