// @ts-nocheck
import { OktaAuth, toRelativeUrl } from '@okta/okta-auth-js';
import { LoginCallback, SecureRoute, Security } from '@okta/okta-react';
import { Redirect, Route, Switch, useHistory } from 'react-router-dom';
import { ROUTES } from 'src/constants';
import Home from 'src/modules/Home';
import Login from 'src/modules/OktaAuth';
import { oktaAuthConfig, oktaSignInConfig } from './config';

const oktaAuth = new OktaAuth(oktaAuthConfig);

const AppWithOktaAuth = () => {
  const history = useHistory();

  const customAuthHandler = () => {
    history.push(ROUTES.login);
  };

  const restoreOriginalUri = async (_oktaAuth: any, originalUri: any) => {
    history.replace(toRelativeUrl(originalUri || '/', window.location.origin));
  };

  return (
    <Security
      oktaAuth={oktaAuth}
      onAuthRequired={customAuthHandler}
      restoreOriginalUri={restoreOriginalUri}>
      <Switch>
        <Route
          exact
          path={ROUTES.login}
          render={() => <Login config={oktaSignInConfig} />}
        />
        <Route exact path="/login/callback" component={LoginCallback} />
        <Route exact path="/">
          {oktaAuth?.authState?.isAuthenticated ? (
            <Redirect to="/app" />
          ) : (
            <Redirect to="/login" />
          )}
        </Route>
        <SecureRoute path={ROUTES.appRoot} component={Home} />
        <Route path="*">
          <Redirect to={ROUTES.login} />
        </Route>
      </Switch>
    </Security>
  );
};
export default AppWithOktaAuth;
