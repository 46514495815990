export const PARTNER_PAYMENT_HEADERS = [
  { key: 'partnerName', label: 'Partner Name' },
  { key: 'mobileNumber', label: 'Mobile Number' },
  { key: 'role', label: 'Role' },
  { key: 'enterprise', label: 'Enterprise' },
  { key: 'state', label: 'State' },
  { key: 'pancard', label: 'Pan Card' },
  { key: 'totalAmountExTax', label: 'Total Amount Exc Tax' },
  { key: 'tax', label: 'Tax' },
  { key: 'totalAmountInclTax', label: 'Total Amount Inc Tax' }
];

export const PAYMENT_BREAKDOWN_HEADERS = [
  { key: 'jobName', label: 'Job name' },
  { key: 'noOfUser', label: 'No of users' },
  { key: 'bucket', label: 'Bucket' },
  { key: 'incentiveRate', label: 'Incentive Rate' },
  { key: 'rateMultiplier', label: 'Rate Multiplier' },
  { key: 'amount', label: 'Amount' }
];

export interface RolesInterface {
  active: boolean;
  displayText: string;
  role: string;
}

export interface VendorPaymentDataInterface {
  active: boolean;
  displayText: string;
  role: string;
}

export interface LatestReportLinkInterface {
  active: boolean;
  payoutCycleId: number;
  fromDate: string;
  toDate: string;
  reportGeneratedOn: string;
  reportLink: string;
}

export const PAGE_SIZE = 20;

export const END_POINTS = {
  roles: '/afs/api/partner/roles',
  paymentCycleByRole: '/afs/api/partner/payout-cycle-by-role',
  partnerPayoutCycle: '/afs/api/partner/partner-payout-cycle',
  partnerPaymentBreakdown: '/afs/api/partner/partner-payout-cycle-breakdown',
  payoutCycleReportLink: '/afs/api/partner/payout-cycle-report-link',
  payoutCycleHistory: '/afs/api/partner/payout-cycle-history',
  partnerBulkUploadStatusTyle: '/afs/api/api/bulk-upload-status-type'
};
