import AccountCircle from '@mui/icons-material/AccountCircle';
import { Box, Typography, Link } from '@mui/material';
import { AppBarProps, default as MuiAppBar } from '@mui/material/AppBar';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { styled } from '@mui/material/styles';
import Toolbar from '@mui/material/Toolbar';
import { useOktaAuth } from '@okta/okta-react';
import * as React from 'react';
import { useState } from 'react';
import SimpleBackdrop from 'src/components/Backdrop';
import { UserDataContext } from 'src/modules/Home';
import { DRAWER_WIDTH, NURTURE_COLOR_GRADIENT } from 'src/constants';
import useOktaInstance from 'src/hooks/useOktaInstance';
import nurtureLogo from 'src/resources/images/partner-admin.svg';

const StyledAppBar = styled(MuiAppBar)<AppBarProps>(({ theme }) => ({
  zIndex: theme.zIndex.drawer + 1,
  marginLeft: DRAWER_WIDTH,
  width: '100%',
  background: NURTURE_COLOR_GRADIENT,
  flexDirection: 'row',
  alignItems: 'center'
}));

const AppBar = () => {
  const auth = useOktaAuth();
  const { userData } = React.useContext(UserDataContext);
  const [userName, setUserName] = useState('');
  const { loading, logout } = useOktaInstance();
  React.useEffect(() => {
    if (auth?.oktaAuth) {
      auth.oktaAuth.getUser().then((data) => {
        setUserName(userData.firstName || data.user || data.sub);
      });
    }
  }, [auth?.oktaAuth, userData.firstName]);
  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const handleClose = () => {
    setAnchorEl(null);
  };
  const logoutUser = async () => {
    handleClose();
    logout();
  };
  return (
    <StyledAppBar position="absolute">
      <Toolbar
        sx={{
          display: 'flex',
          alignItems: 'center'
        }}>
        <Box
          sx={{
            textAlign: 'center',
            width: '100%',
            cursor: 'pointer'
          }}>
          <Link href="/app" sx={{ color: 'white' }} underline="none">
            <img src={nurtureLogo} alt="Nurture Logo"></img>
          </Link>
        </Box>
      </Toolbar>
      <Toolbar sx={{ width: '100%' }}>
        <Typography
          variant="body1"
          component="div"
          sx={{ flexGrow: 1, paddingLeft: '20px' }}>
          <Link href="/app" sx={{ color: 'white' }} underline="none">
            Partner Admin Portal
          </Link>
        </Typography>

        <Box
          display="flex"
          sx={{ justifyContent: 'center', alignItems: 'center' }}>
          <Typography variant="body1" sx={{ flexGrow: 1 }}>
            {userName}
          </Typography>
          <IconButton
            size="large"
            aria-label="account of current user"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            onClick={handleMenu}
            color="inherit">
            <AccountCircle />
          </IconButton>
          {process.env.REACT_APP_USE_OKTA === 'true' && (
            <Menu
              id="menu-appbar"
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right'
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right'
              }}
              open={Boolean(anchorEl)}
              onClose={handleClose}>
              <MenuItem onClick={logoutUser}>Log Out</MenuItem>
            </Menu>
          )}
        </Box>
      </Toolbar>
      <SimpleBackdrop open={loading} />
    </StyledAppBar>
  );
};

export default AppBar;
