import Dashboard from './modules/Dashboard';
import PartnerDetails from './modules/PartnerDetails';
import PartnerPaymentCalculation from './modules/PaymentCalculations/PartnerLevelEnrichedData';
import VendorPaymentCalculation from './modules/PaymentCalculations/VendorPayment';
import PersonIcon from '@mui/icons-material/Person';
import {
  Campaign,
  PaymentOutlined,
  UploadFile,
  YouTube
} from '@mui/icons-material';
import Announcements from './modules/Inbox/Announcements';
import AddAnnouncement from './modules/Inbox/Announcements/create';
import Tutorials from './modules/Inbox/Tutorials';
import AddTutorial from './modules/Inbox/Tutorials/create';
import PartnerUploads from './modules/PartnerUpload';

export const ROUTES = {
  partnerDetails: '/partner-details',
  dashboard: '/',
  appRoot: '/app',
  unauthenticated: '/unauthenticated',
  login: '/login',
  partnerPayrollFiles: '/partner/payroll/files',
  partnerPayoutEarnings: '/partner/payout/earnings',
  announcements: '/inbox/announcements',
  addAnnouncements: '/inbox/announcements/create',
  tutorials: '/inbox/tutorials',
  addTutorial: '/inbox/tutorials/create',
  partnerUploads: '/partner-uploads'
};

export const PERMISSIONS = {
  PARTNER_PORTAL_SUPERADMIN: 'partner_portal_superadmin',
  PARTNER_PERFORMANCE: 'PARTNER_PERFORMANCE',
  PARTNER_PAYROLL_FILES: 'PARTNER_PAYROLL_FILES',
  PARTNER_EARNINGS: 'PARTNER_EARNINGS',
  CONTENT_MANAGEMENT: 'view_content_management_tab',
  VIEW_PARTNER_UPLOADS: 'view_partner_details_tab'
};

export const PRIVATE_ROUTES = [
  { path: '/', component: Dashboard },
  {
    path: ROUTES.partnerDetails,
    permissions: [
      PERMISSIONS.PARTNER_PORTAL_SUPERADMIN,
      PERMISSIONS.PARTNER_PERFORMANCE
    ],
    component: PartnerDetails
  },
  {
    path: ROUTES.partnerPayrollFiles,
    permissions: [
      PERMISSIONS.PARTNER_PORTAL_SUPERADMIN,
      PERMISSIONS.PARTNER_PAYROLL_FILES
    ],
    component: VendorPaymentCalculation
  },
  {
    path: ROUTES.partnerPayoutEarnings,
    permissions: [
      PERMISSIONS.PARTNER_PORTAL_SUPERADMIN,
      PERMISSIONS.PARTNER_EARNINGS
    ],
    component: PartnerPaymentCalculation
  },
  {
    path: ROUTES.announcements,
    permissions: [
      PERMISSIONS.PARTNER_PORTAL_SUPERADMIN,
      PERMISSIONS.CONTENT_MANAGEMENT
    ],
    component: Announcements
  },
  {
    path: ROUTES.addAnnouncements,
    permissions: [
      PERMISSIONS.PARTNER_PORTAL_SUPERADMIN,
      PERMISSIONS.CONTENT_MANAGEMENT
    ],
    component: AddAnnouncement
  },
  {
    path: ROUTES.tutorials,
    // permissions: [PARTNER_PORTAL_SUPERADMIN],
    component: Tutorials
  },
  {
    path: ROUTES.addTutorial,
    // permissions: [PARTNER_PORTAL_SUPERADMIN],
    component: AddTutorial
  },
  {
    path: ROUTES.partnerUploads,
    permissions: [
      PERMISSIONS.PARTNER_PORTAL_SUPERADMIN,
      PERMISSIONS.VIEW_PARTNER_UPLOADS
    ],
    component: PartnerUploads
  }
];

export const AUTH_STATUS = {
  pending: 'pending',
  unauthenticated: 'unauthenticated',
  authenticated: 'authenticated'
};

export interface UserDataResponseInterface {
  activated: boolean;
  active: boolean;
  authorities: Array<{
    serverId: number;
    active: boolean;
    authority: string;
  }>;
  firstName: string;
  login: string;
  oauthResponseDTO: {
    access_token: string;
    active: boolean;
    refresh_token: string;
    scope: string;
    token_type: string;
  };
  roles: Array<string>;
}
export const DEFAULT_USER_DATA: UserDataResponseInterface = {
  activated: false,
  active: false,
  authorities: [],
  firstName: '',
  login: '',
  oauthResponseDTO: {
    access_token: '',
    active: false,
    refresh_token: '',
    scope: '',
    token_type: 'bearer'
  },
  roles: []
};

export const DRAWER_WIDTH = 280;
export const NURTURE_THEME_COLOR_LIGHT = 'rgba(84,42,177,1)';
export const NURTURE_COLOR_GRADIENT =
  'linear-gradient(87.29deg, #192C4A -38.84%, #332E93 37.04%, #542AB1 114.49%)';
export const PRIMARY_BACKGROUND_COLOR =
  'linear-gradient(267.6deg, #672FB7 -12%, #4225AB 124.73%)';
export const PRIMARY_COLOR = '#4003A6';
export const SECONDARY_BACKGROUND_COLOR = 'rgba(0, 0, 0, 0.3)';

export const DEFAULT_ERROR_MESSAGE = 'Something went wrong';

export const PARTNER_SUPERADMIN_ITEMS = [
  {
    route: ROUTES.partnerDetails,
    icon: PersonIcon,
    title: 'Partner Details',
    permission: PERMISSIONS.PARTNER_PERFORMANCE
  },
  {
    route: ROUTES.partnerPayoutEarnings,
    icon: PaymentOutlined,
    title: 'Partner Earnings',
    permission: PERMISSIONS.PARTNER_EARNINGS
  },
  {
    route: ROUTES.partnerPayrollFiles,
    icon: PaymentOutlined,
    title: 'Partner Payroll Files'
  },
  {
    route: ROUTES.partnerUploads,
    icon: UploadFile,
    title: 'Partner Uploads'
  },
  {
    title: 'Inbox',
    route: '',
    icon: PaymentOutlined,
    permission: PERMISSIONS.CONTENT_MANAGEMENT,
    children: [
      {
        route: ROUTES.announcements,
        icon: Campaign,
        title: 'Announcements',
        depth: 1
      },
      {
        route: ROUTES.tutorials,
        icon: YouTube,
        title: 'Tutorials',
        depth: 1
      }
    ]
  }
];

export const downloadFileEvent = 'download_report';
export const searchPartnerByMobileEvent = 'search_partner_by_mobile';

export const SUPPORT_MAIL_ID = 'https://partner-admin.nurture.farm';
export const ACCESS_MAIL_ID = 'partner-admin-access@nurture.farm';
export const PARTNER_PAYROLL_ITEMS = [
  {
    route: ROUTES.partnerPayrollFiles,
    icon: PaymentOutlined,
    title: 'Partner Payroll Files'
  }
];

export const PARTNER_EARNING_ITEMS = [
  {
    route: ROUTES.partnerPayoutEarnings,
    icon: PaymentOutlined,
    title: 'Partner Earnings'
  }
];

export const BORDER_COLOR = '#4003A6';

export const getErrorMsg = (err) => {
  if (err && err.status === 401) {
    return err.statusText ? err.statusText : 'Session Expired';
  } else if (err.status === 400) {
    return err.error.title ? err.error.title : 'Bad Request';
  } else if (err.status === 403) {
    return err.error.detail ? err.error.title : 'Access is denied.';
  } else if (err.status === 404) {
    return err.error.responseMessage
      ? err.error.responseMessage
      : err.error.message;
  } else if (err.status === 500) {
    return err.statusText ? err.statusText : 'Something went wrong.';
  } else if (err.status === 503) {
    return err.statusText;
  } else {
    return 'Some error occured please open console or network mode to see';
  }
};

export const createRolesData = (roles) => {
  const rolesData = roles.map((role) => {
    const [vertical, name] = role.displayText.split(' - ');
    return {
      label: role.displayText,
      value: `${vertical}-${role.role}-${name}` // need vertical for payment cycle api's.
    };
  });

  return rolesData;
};
