import React, { useEffect, useState } from 'react';
import { Box } from '@mui/system';
import { useSnackbar } from 'notistack';
import useAxios from 'src/hooks/useAxios';
import { CircularProgress } from '@mui/material';
import { getPartnerPayoutList, getRoles, getPaymentCycle } from './actions';
import { PaymentCycleInterface, RolesInterface } from './constants';
import { getLatestReportLink } from '../VendorPayment/actions';
import PaymentCalculationsHeader from './Header';
import PartnerPaymentList from './List';
import { PAGE_SIZE } from '../constants';
import { DEFAULT_ERROR_MESSAGE } from 'src/constants';
import SimpleBackdrop from 'src/components/Backdrop';
import { IPartnerRole } from 'src/modules/Inbox/Interfaces';

const PartnerPaymentCalculation = () => {
  const [roles, setRoles] = useState<Array<IPartnerRole> | []>([]);
  const [selectedRole, setSelectedRole] = useState('');
  const [paymentCycleId, setPaymentCycleId] = useState();
  const [tableData, setTableData] = React.useState<any>([]);
  const [filteredTableData, setFilteredTableData] = React.useState([]);
  const [searchText, setSearchText] = React.useState('');
  const [paymentCycleData, setPaymentCycleData] = useState<
    PaymentCycleInterface | []
  >([]);
  const [showTable, setShowTable] = React.useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [page, setPage] = React.useState(1);
  const [pageCount, setpageCount] = React.useState(1);
  const [availablePages, setAvailablePages] = useState<any>([]);
  const [latestReportData, setLatestReportData] = useState<any>({});

  const API = useAxios();

  const fetchPayoutCycleData = async (changedRole: string) => {
    setIsLoading(true);
    try {
      const response = await getPaymentCycle(API, changedRole);
      if (response && response.data && response.data.dataList) {
        setPaymentCycleData(response.data.dataList);
      }
    } finally {
      setIsLoading(false);
    }
  };

  const fetchRoleData = async () => {
    setIsLoading(true);
    try {
      const res = await getRoles(API);
      if (res && res.data && res.data.dataList) {
        setRoles(res.data.dataList);
      }
    } finally {
      setIsLoading(false);
    }
  };

  const handleSearchTextChange = (partnerMobileNumber: string) => {
    setSearchText(partnerMobileNumber);
  };

  const fetchLatestReportLinkData = async (payoutCycleId) => {
    setIsLoading(true);
    setLatestReportData(undefined);
    const number = parseInt(searchText);
    const withBreakdown = true;
    try {
      const response = await getLatestReportLink(
        API,
        selectedRole,
        payoutCycleId,
        number,
        withBreakdown
      );
      if (response && response.data && response.data.data) {
        setLatestReportData(response.data.data);
      }
    } finally {
      setIsLoading(false);
    }
  };

  const handlePaymentCycleChange = async (changedCycleId) => {
    setPaymentCycleId(changedCycleId);
    fetchLatestReportLinkData(changedCycleId);
  };

  const fetchData = () => {
    setPage(1);
    setFilteredTableData([]);
    setpageCount(1);
    setAvailablePages([]);
    fetchTableData(1, []);
    fetchLatestReportLinkData(paymentCycleId);
  };

  const fetchTableData = async (
    changedPage = 1,
    pagesList: any = availablePages
  ) => {
    localStorage.removeItem('expandedPayments');
    setIsLoading(true);
    setShowTable(true);
    const number = parseInt(searchText);
    try {
      const res = await getPartnerPayoutList(
        API,
        selectedRole,
        paymentCycleId,
        changedPage - 1,
        number
      );
      if (res && res.data && res.data.dataList) {
        setTableData([...tableData, ...res.data.dataList]);
        if (res.data.dataList.length) {
          if (
            res.data.dataList.length >= PAGE_SIZE &&
            pagesList.indexOf(changedPage) === -1
          ) {
            setpageCount((pageCount) => pageCount + 1);
            setAvailablePages([...pagesList, changedPage]);
          }
        }
        setFilteredTableData(res.data.dataList);
      }
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchRoleData();
  }, []);

  return (
    <Box>
      <PaymentCalculationsHeader
        roles={roles}
        selectedRole={selectedRole}
        setSelectedRole={setSelectedRole}
        paymentCycleId={paymentCycleId}
        handlePaymentCycleChange={handlePaymentCycleChange}
        fetchTableData={fetchData}
        isDataAvailable={tableData.length}
        handleSearchTextChange={handleSearchTextChange}
        searchText={searchText}
        fetchPayoutCycleData={fetchPayoutCycleData}
        paymentCycleData={paymentCycleData}
        latestReportData={latestReportData}
      />
      {showTable && (
        <PartnerPaymentList
          tableData={filteredTableData}
          selectedRole={selectedRole}
          paymentCycleId={paymentCycleId}
          page={page}
          setPage={setPage}
          fetchTableData={fetchTableData}
          pageCount={pageCount}
          setSearchText={setSearchText}
        />
      )}
      {isLoading && (
        <CircularProgress
          sx={{
            position: 'absolute',
            left: '50%',
            top: '40%',
            color: '#505a75'
          }}
          size={30}
        />
      )}
    </Box>
  );
};

export default PartnerPaymentCalculation;
