import { useContext, useState, useEffect } from 'react';
import styled from '@emotion/styled';
import { AddCircleOutline } from '@mui/icons-material';
import { Box, Grid, TextField } from '@mui/material';
import { useHistory } from 'react-router-dom';
import CustomDropdown from 'src/components/CustomDropdown';
import { createRolesData, PRIMARY_COLOR, ROUTES } from 'src/constants';
import CustomButton from 'src/components/CustomButton';
import { getRoles } from '../PaymentCalculations/PartnerLevelEnrichedData/actions';
import useAxios from 'src/hooks/useAxios';
import { IPartnerRole } from './Interfaces';
import { getAllStates } from './actions';

const StyledSearch = styled(TextField)(() => ({
  ' & .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
    borderColor: PRIMARY_COLOR
  },
  ' & .MuiInputLabel-root.Mui-focused': {
    color: PRIMARY_COLOR
  }
}));

const InboxFilters = ({
  isTutorial,
  setSelectedRole,
  setSelectedState,
  selectedState,
  selectedRole,
  setSearchText,
  searchText
}) => {
  const history = useHistory();
  const [partnerRoles, setPartnerRoles] = useState<Array<IPartnerRole> | []>(
    []
  );
  const [states, setStates] = useState<any>([]);
  const API = useAxios();

  useEffect(() => {
    (async function () {
      const res = await getRoles(API);
      if (res?.data?.dataList) {
        const roles: Array<IPartnerRole> = res.data.dataList;
        setPartnerRoles(roles);
      }
    })();
  }, []);

  useEffect(() => {
    (async function () {
      const res = await getAllStates({ API });
      if (res?.data) {
        setStates(res?.data);
      }
    })();
  }, []);

  const createStatesDropdownData = () => {
    let data = [];
    if (states && states.length) {
      data = states.map((state) => {
        return {
          label: state.geographyName,
          value: state.serverId
        };
      });
    }
    return data;
  };

  return (
    <Box
      display="flex"
      marginBottom="40px"
      alignItems={'center'}
      justifyContent="space-between">
      <Grid container spacing={2} alignItems="center">
        <Grid item sm={6} md={4} lg={3}>
          <StyledSearch
            label={
              isTutorial
                ? 'Search by Tutorial Title'
                : 'Search by Announcement Title'
            }
            placeholder="Search"
            size="small"
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
            sx={{
              width: '80%',
              '& div': {
                borderRadius: '12px'
              }
            }}
            inputProps={{
              maxLength: 10
            }}
          />
          {/* {searchText.length > 0 && searchText.length < 10 && (
              <Typography color={'red'}>Please enter valid number</Typography>
            )} */}
        </Grid>
        {/* <Grid item sm={6} md={4} lg={3}>
          <CustomDropdown
            options={createStatesDropdownData()}
            value={selectedState}
            onChange={(value) => setSelectedState(value)}
            label="Select State"
            width="80%"
          />
        </Grid>
        <Grid item sm={6} md={4} lg={3}>
          <CustomDropdown
            options={createRolesData(partnerRoles)}
            value={selectedRole}
            onChange={(value) => setSelectedRole(value)}
            label="Select Partners"
            width="80%"
          />
        </Grid> */}

        <Grid item sm={6} md={4} lg={3}>
          <CustomButton
            title={isTutorial ? 'Add Tutorial' : 'Add Announcment'}
            buttonType="primary"
            variant="contained"
            startIcon={<AddCircleOutline />}
            onClick={() =>
              isTutorial
                ? history.push(ROUTES.addTutorial)
                : history.push(ROUTES.addAnnouncements)
            }
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default InboxFilters;
