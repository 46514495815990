import { END_POINTS, PAGE_SIZE } from './constant';

const {
  partnerBulkUploadStatusTyle,
  partnerUploadHistory,
  partnerUploadErrors,
  partnerAllErrors,
  uploadF3Report
} = END_POINTS;

export const getBulkTypeStatus = async (axiosInstance) => {
  const res = await axiosInstance.get(partnerBulkUploadStatusTyle);
  return res;
};

export const getPartnerUploadHistory = async (axiosInstance, currentPage) => {
  const res = await axiosInstance.post(partnerUploadHistory, {
    pageNumber: currentPage,
    limit: PAGE_SIZE
  });
  return res;
};

export const getPartnerUploadErrorDetails = async (
  axiosInstance,
  currentPage,
  fileId
) => {
  const res = await axiosInstance.post(partnerUploadErrors, {
    pageNumber: currentPage,
    limit: PAGE_SIZE,
    fileId
  });
  return res;
};

export const getAllPartnerUploadErrors = async (axiosInstance, fileId) => {
  const res = await axiosInstance.get(`${partnerAllErrors}/${fileId}`);
  return res;
};

export const bulkUploadFile = async (axiosInstance, status, excelData) => {
  const res = await axiosInstance.post(
    `${uploadF3Report}?type=${status}`,
    excelData
  );
  return res;
};
