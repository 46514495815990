import uplLogo from 'src/resources/images/upl-logo.png';
const clientId = process.env.REACT_APP_OKTA_CLIENT_ID;
const oktaDomain = process.env.REACT_APP_OKTA_DOMAIN;
const oktaAuthConfig = {
  // Note: If your app is configured to use the Implicit flow
  // instead of the Authorization Code with Proof of Code Key Exchange (PKCE)
  // you will need to add `pkce: false`
  issuer: `https://${oktaDomain}/oauth2/default`,
  clientId: `${clientId}`,
  redirectUri: window.location.origin + '/login/callback',
  pkce: false,
  postLogoutRedirectUri: window.location.origin,
  scopes: ['openid']
};

const oktaSignInConfig = {
  baseUrl: `https://${oktaDomain}`,
  clientId: `${clientId}`,
  logo: uplLogo,
  scopes: ['openid'],
  postLogoutRedirectUri: window.location.origin,
  redirectUri: window.location.origin + '/login/callback',
  authParams: {
    // If your app is configured to use the Implicit flow
    // instead of the Authorization Code with Proof of Code Key Exchange (PKCE)
    // you will need to uncomment the below line
    pkce: false
  }
  // Additional documentation on config options can be found at https://github.com/okta/okta-signin-widget#basic-config-options
};

export { oktaAuthConfig, oktaSignInConfig };
