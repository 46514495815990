import { AxiosInstance } from 'axios';
import { END_POINTS, PAGE_SIZE } from './constants';
import {
  IAllAnnouncementTutorial,
  IFetchAllAnnouncementsProps,
  IUploadMediaFunction
} from './Interfaces';

const { allAnnouncenmentsRoute, uploadMediaRoute, geographyData } = END_POINTS;

export const getAllAnnouncenments: IFetchAllAnnouncementsProps = async ({
  API,
  appName,
  pageNumber,
  selectedRole,
  state,
  geographyId,
  noticeType
}) => {
  const [vertical, role] = selectedRole.split('-');
  let body = {
    role: role || '',
    appName,
    pageNumber,
    limit: PAGE_SIZE,
    geographyId,
    noticeType
  };
  const response: IAllAnnouncementTutorial = await API.post(
    allAnnouncenmentsRoute,
    body
  );
  return response;
};

export const uploadFileToS3Bucket: IUploadMediaFunction = async (
  API: AxiosInstance,
  body
) => {
  const res = await API.post(uploadMediaRoute, body);
  return res;
};

export const getAllStates = async ({ API }) => {
  const response = await API.get(geographyData);
  return response;
};

export const createNewAnnouncement = async (API: AxiosInstance, body) => {
  const [, role] = body?.role?.split('-');
  body.role = role;
  const res = await API.post('/announcement', body);
  return res;
};