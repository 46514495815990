import { AxiosInstance } from 'axios';
import moment from 'moment';
import { useSnackbar } from 'notistack';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import SimpleBackdrop from 'src/components/Backdrop';
import { ROUTES } from 'src/constants';
import useAxios from 'src/hooks/useAxios';
import { createNewAnnouncement } from '../actions';
import { APP_NAME, NOTICE_TYPES } from '../constants';
import InboxForm from '../form';

const AddTutorial = () => {
  const API: AxiosInstance = useAxios();
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();

  const createData = (values, mediaData) => {
    const data = { ...values };
    data.endDate = moment(values.endDate).valueOf();
    data.startDate = moment(values.startDate).valueOf();
    data.appName = APP_NAME;
    data.announcementMediaId = mediaData?.announcementMediaId;
    data.announcementMediaURL = mediaData?.announcementMediaURL;
    delete data.img;
    data.noticeType = NOTICE_TYPES.tutorial;
    return data;
  };

  const handleCreate = async (values, mediaData) => {
    const newAnnouncementdata = createData(values, mediaData);
    setLoading(true);
    const response = await createNewAnnouncement(API, newAnnouncementdata);
    if (response?.data) {
      enqueueSnackbar('Tutorial created successfully', {
        variant: 'success'
      });
      history.push(ROUTES.tutorials);
    }
    setLoading(false);
  };

  return (
    <>
      <InboxForm isTutorial={true} handleCreate={handleCreate} />
      <SimpleBackdrop open={loading} />
    </>
  );
};

export default AddTutorial;
