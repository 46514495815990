import { useState, FC } from 'react';
import { RemoveRedEyeOutlined } from '@mui/icons-material';
import { DataGrid } from '@mui/x-data-grid';
import CustomPagination from 'src/components/Pagination';
import { PRIMARY_COLOR } from 'src/constants';
import InboxFilters from './Filters';
import MobileView from './MobileView';
import { IInboxTableProps } from './Interfaces';
import moment from 'moment';
import { Box } from '@mui/material';

const InboxTable: FC<IInboxTableProps> = ({
  data,
  isTutorial,
  currentPage,
  setCurrentPage,
  pageCount,
  setPageCount,
  setSelectedRole,
  setSelectedState,
  selectedRole,
  selectedState,
  setSearchText,
  searchText
}) => {
  const [showMobileView, setShowMobileView] = useState<boolean>(false);

  const announcementColumns = [
    {
      field: 'createdDate',
      headerName: 'Created Date',
      width: 200,
      renderCell: (params) => moment(params.value).format('MMM DD YYYY hh:mm a')
    },
    { field: 'appName', headerName: 'Created By', width: 190 },
    { field: 'title', headerName: 'Title', width: 220 },
    { field: 'description', headerName: 'Description', width: 300 },
    {
      field: 'startDate',
      headerName: 'Starting Date',
      width: 200,
      renderCell: (params) => moment(params.value).format('MMM DD YYYY hh:mm a')
    },
    {
      field: 'endDate',
      headerName: 'Ending Date',
      width: 200,
      renderCell: (params) => moment(params.value).format('MMM DD YYYY hh:mm a')
    }
    // IN Next Sprint
    // { field: 'likeCount', headerName: 'Like Count', width: 120 },
    // { field: 'sharedCount', headerName: 'Shared Count', width: 120 },
    // { field: 'commentCount', headerName: 'Comment Count', width: 120 }
    // {
    //   field: 'mobileView',
    //   headerName: 'Mobile View',
    //   width: 200,
    //   renderCell: (params) => (
    //     <strong>
    //       <RemoveRedEyeOutlined
    //         fontSize="medium"
    //         onClick={() => setShowMobileView(true)}
    //         sx={{ cursor: 'pointer', color: PRIMARY_COLOR }}
    //       />
    //     </strong>
    //   )
    // }
  ];

  const onPageChange = (page) => {
    setCurrentPage(page);
    setPageCount((pageCount) => pageCount + 1);
  };

  return (
    <>
      <InboxFilters
        isTutorial={isTutorial}
        setSelectedRole={setSelectedRole}
        setSelectedState={setSelectedState}
        selectedState={selectedState}
        selectedRole={selectedRole}
        setSearchText={setSearchText}
        searchText={searchText}
      />
      <DataGrid
        rows={data}
        columns={announcementColumns.map((column) => ({
          ...column,
          sortable: false
        }))}
        disableColumnMenu
        disableSelectionOnClick
        autoHeight
        sx={{
          '&.MuiDataGrid-root .MuiDataGrid-cell:focus': {
            outline: 'none'
          },
          '& .MuiDataGrid-root': {
            border: 'none'
          },
          '& .MuiDataGrid-footerContainer': {
            display: 'none'
          },
          '& .MuiDataGrid-cell': {},
          '& .MuiDataGrid-columnHeaderTitle': {
            fontWeight: 'bold',
            opacity: '0.7'
          }
        }}
      />
      <CustomPagination
        page={currentPage}
        setPage={onPageChange}
        pageCount={pageCount}
      />
      {showMobileView && <MobileView setShowMobileView={setShowMobileView} />}
    </>
  );
};

export default InboxTable;
