export const PAGE_SIZE = 20;

const BASE_URI = '/unimart/api';

export const END_POINTS = {
  partnerBulkUploadStatusTyle: `${BASE_URI}/bulk-upload-status-type`,
  partnerUploadHistory: `${BASE_URI}/get-partner-upload-history`,
  partnerUploadErrors: `${BASE_URI}/get-partner-upload-error-details-pagination`,
  partnerAllErrors: `${BASE_URI}/get-partner-upload-error-details`,
  uploadF3Report: `${BASE_URI}/uploadPartnerUsers`
};
