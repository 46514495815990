import React, { useState, useEffect } from 'react';
import * as AWS from 'aws-sdk';
import styled from '@emotion/styled';
import { Formik, Form } from 'formik';
import { AxiosInstance } from 'axios';
import * as yup from 'yup';
import { CancelOutlined, Upload } from '@mui/icons-material';
import { Box, Typography } from '@mui/material';
import CustomButton from 'src/components/CustomButton';
import {
  announcementTypesOptions,
  getCurrentDate,
  ANNOUNCEMENT_TYPES,
  LINK_REGEX,
  MEDIA_SOURCE,
  BUCKET_PATH
} from './constants';
import useAxios from 'src/hooks/useAxios';
import { getAllStates, uploadFileToS3Bucket } from './actions';
import CustomTextField from 'src/components/CustomTextField';
import { createRolesData } from 'src/constants';
import { getRoles } from '../PaymentCalculations/PartnerLevelEnrichedData/actions';
import { useSnackbar } from 'notistack';
import { IPartnerRole } from './Interfaces';

let awsService, s3Obj;

const InboxForm = ({ isTutorial, handleCreate }) => {
  const [partnerRoles, setPartnerRoles] = useState<Array<IPartnerRole> | []>(
    []
  );

  const [states, setStates] = useState<any>([]);
  const [isUrlUploaded, setIsUrlUploaded] = useState<boolean>(false);

  const createStatesDropdownData = () => {
    let data = [];
    if (states && states.length) {
      data = states.map((state) => {
        return {
          label: state.geographyName,
          value: state.serverId
        };
      });
    }
    return data;
  };

  useEffect(() => {
    (async function () {
      const res = await getAllStates({ API });
      if (res?.data) {
        setStates(res?.data);
      }
    })();
  }, []);

  useEffect(() => {
    (async function () {
      const res = await getRoles(API);
      if (res?.data?.dataList) {
        const roles: Array<IPartnerRole> = res.data.dataList;
        setPartnerRoles(roles);
      }
    })();
  }, []);

  const mockPartners = [
    { id: 1, label: 'Partner 1', value: 2 },
    { id: 2, label: 'Partner 2', value: 'Partner 2' }
  ];
  const [uploadedFileData, setUploadedFileData] = useState<any>({});
  const [selectedImg, setSelectedImg] = useState<string | null>(null);

  const API: AxiosInstance = useAxios();

  useEffect(() => {
    /**
     * AWS config initialization
     */
    awsService = AWS;
    awsService.config.accessKeyId = process.env.REACT_APP_AWS_ACCESS_KEY;
    awsService.config.secretAccessKey = process.env.REACT_APP_AWS_SECRET_KEY;
    awsService.config.region = process.env.REACT_APP_AWS_REGION;
    s3Obj = new AWS.S3({ params: { Bucket: process.env.s3_bucket } });
  }, []);

  const onSubmit = (data) => {
    handleCreate(data, uploadedFileData);
  };

  const getUniqueName = (name: string) => {
    let _utils = {
      _extension_r: /\.[a-z]+$/i,
      _filename_r: /[^a-zA-Z0-9]/g
    };
    let extension = name.match(_utils._extension_r);
    let filename: string =
      name.replace(_utils._extension_r, '') +
      '_' +
      Math.random().toString(36).substr(2, 2);
    return filename + extension?.[0];
  };

  const { enqueueSnackbar } = useSnackbar();

  const getSignedS3URL = (key: String) => {
    return s3Obj.getSignedUrl('getObject', {
      Bucket: process.env.REACT_APP_s3_bucket,
      Key: key
    });
  };

  const uploadFiles = (file: File, fileType: string) => {
    let bucketPath,
      params,
      fileUrl,
      fileObject,
      mediaData = {};
    bucketPath = BUCKET_PATH;
    params = {
      Bucket: process.env.REACT_APP_s3_bucket_client,
      Key: bucketPath + getUniqueName(file.name),
      Body: file,
      ACL: 'public-read'
    };
    s3Obj.upload(params, async (err, data) => {
      if (data) {
        fileUrl = data.Key;
        fileObject = {
          active: true,
          path: fileUrl,
          mimeType: fileType,
          size: file.size ? file.size : null,
          mediaSource: MEDIA_SOURCE.s3
        };
        const mediaObject = await uploadFileToS3Bucket(API, fileObject);
        if (mediaObject) {
          enqueueSnackbar('File uploaded successfully', {
            variant: 'success'
          });
          mediaData['announcementMediaURL'] = getSignedS3URL(
            mediaObject?.data?.path
          );
          mediaData['announcementMediaId'] = mediaObject?.data?.serverId;
          setUploadedFileData(mediaData);
        }
      }
    });
  };

  const uploadYouTubeLink = async (url) => {
    const body = {
      active: true,
      url,
      size: null,
      mediaSource: MEDIA_SOURCE.youtube
    };
    const mediaObject = await uploadFileToS3Bucket(API, body);
    if (mediaObject) {
      enqueueSnackbar('Link uploaded successfully', {
        variant: 'success'
      });
      setIsUrlUploaded(true);
      const mediaData = {};
      mediaData['announcementMediaURL'] = mediaObject?.data?.path;
      mediaData['announcementMediaId'] = mediaObject?.data?.serverId;
      setUploadedFileData(mediaData);
    }
  };

  const onImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target?.files?.[0]) {
      let file: File = event.target.files[0];
      let fileType = '';
      if (file && file.name) {
        fileType = file.name
          .substr(file.name.lastIndexOf('.') + 1)
          .toLowerCase();
      }
      let img = URL.createObjectURL(file);
      setSelectedImg(img);
      if (fileType === 'jpeg' || fileType === 'jpg' || fileType === 'png') {
        let myReader: FileReader = new FileReader();
        myReader.onloadend = (loadEvent: any) => {
          uploadFiles(file, fileType);
        };
        myReader.readAsDataURL(file);
      } else {
        // file format error
      }
    }
  };

  const inboxValidationSchema = yup.object().shape({
    title: yup.string().required('Title is required*'),
    startDate: yup.string().required('Start Date is required*'),
    endDate: yup.string().required('End Date is required*'),
    description: yup.string().required('Description is required*'),
    geographyId: yup.string().nullable().required('State is required*'),
    role: yup.string().nullable().required('Role is required*')
  });

  const tutorialSchema = yup.object().shape({
    youtubeLink: yup
      .string()
      .matches(LINK_REGEX, 'Please enter valid link')
      .required('Youtube Link is required*')
  });

  const announcementTypeSchema = yup.object().shape({
    announcementType: yup.string().required('Announcement Type is required*'),
    img: yup.string().when('announcementType', {
      is: (value) => value && value === ANNOUNCEMENT_TYPES.image,
      then: yup.string().required('Image is required*')
    }),
    youtubeLink: yup.string().when('announcementType', {
      is: (value) => value && value === ANNOUNCEMENT_TYPES.video,
      then: yup
        .string()
        .matches(LINK_REGEX, 'Please enter valid link')
        .required('Youtube Link is required*')
    })
  });

  // adding announcementType needed for Announcements.
  const validationSchema = isTutorial
    ? inboxValidationSchema.concat(tutorialSchema)
    : inboxValidationSchema.concat(announcementTypeSchema);

  const initialValues = {
    title: '',
    startDate: '',
    endDate: '',
    geographyId: '',
    role: '',
    announcementType: '',
    description: '',
    img: '',
    youtubeLink: ''
  };

  const checkForUrlUpload = (type) => {
    if (isTutorial) {
      return isUrlUploaded;
    } else if (type == ANNOUNCEMENT_TYPES.video) {
      if (isUrlUploaded) {
        return true;
      } else {
        return false;
      }
    } else {
      return true;
    }
  };

  return (
    <Box sx={{ marginTop: '100px' }}>
      <Formik
        initialValues={initialValues}
        onSubmit={(values, actions) => {
          onSubmit(values);
        }}
        validationSchema={validationSchema}>
        {({
          handleSubmit,
          setFieldValue,
          values,
          errors,
          touched,
          dirty,
          isValid,
          handleChange,
          handleBlur,
          setTouched
        }) => (
          <Form>
            <Typography
              sx={{
                fontSize: '22px',
                marginBottom: '40px',
                fontWeight: 600
              }}>
              {isTutorial ? 'ADD TUTORIAL' : 'ADD ANNOUNCEMENT'}
            </Typography>

            <Box sx={{ display: 'flex' }}>
              <CustomTextField
                label={isTutorial ? 'Tutorial Title' : 'Announcement Title'}
                name="title"
                required
                helperText={touched.title ? errors.title : ''}
                error={touched.title && Boolean(errors.title)}
                type="text"
                value={values.title}
                onBlur={handleBlur}
                onChange={handleChange}
                sx={{
                  width: '40%',
                  marginRight: '20px',
                  '& div': {
                    borderRadius: '12px'
                  }
                }}
              />
              <Box sx={{ display: 'flex', width: '50%' }}>
                <CustomTextField
                  id="startDate"
                  label="From"
                  name="startDate"
                  required
                  value={values.startDate}
                  helperText={touched.startDate ? errors.startDate : ''}
                  error={touched.startDate && Boolean(errors.startDate)}
                  onBlur={handleBlur}
                  type="date"
                  InputLabelProps={{
                    shrink: true
                  }}
                  inputProps={{
                    min: getCurrentDate(0)
                  }}
                  onChange={handleChange}
                  sx={{ marginRight: '20px', width: '100%' }}
                />
                <CustomTextField
                  id="endDate"
                  label="To"
                  required
                  helperText={touched.endDate ? errors.endDate : ''}
                  error={touched.endDate && Boolean(errors.endDate)}
                  onBlur={handleBlur}
                  name="endDate"
                  type="date"
                  value={values.endDate}
                  InputLabelProps={{
                    shrink: true
                  }}
                  onChange={handleChange}
                  disabled={!values.startDate}
                  inputProps={{
                    min: values.startDate || ''
                  }}
                  sx={{ marginRight: '20px', width: '100%' }}
                />
              </Box>
            </Box>
            <Box
              sx={{
                display: 'flex',
                marginTop: '40px',
                marginBottom: '40px'
              }}>
              <CustomTextField
                id="geographyId"
                label="Select State"
                required
                type="select"
                helperText={touched.geographyId ? errors.geographyId : ''}
                error={touched.geographyId && Boolean(errors.geographyId)}
                onBlur={handleBlur}
                name="geographyId"
                value={values.geographyId}
                InputLabelProps={{
                  shrink: true
                }}
                onChange={handleChange}
                sx={{
                  marginRight: '20px',
                  width: '40%',
                  '& div': {
                    borderRadius: '12px'
                  }
                }}
                select
                menuItems={createStatesDropdownData()}
              />
              <CustomTextField
                id="role"
                label="Select Partner"
                required
                type="select"
                helperText={touched.role ? errors.role : ''}
                error={touched.role && Boolean(errors.role)}
                onBlur={handleBlur}
                name="role"
                value={values.role}
                InputLabelProps={{
                  shrink: true
                }}
                onChange={handleChange}
                sx={{
                  marginRight: '20px',
                  width: '40%',
                  '& div': {
                    borderRadius: '12px'
                  }
                }}
                select
                menuItems={
                  partnerRoles?.length ? createRolesData(partnerRoles) : []
                }
              />
            </Box>
            {!isTutorial && (
              <CustomTextField
                id="announcementType"
                label="Select Announcement Type"
                required={!isTutorial}
                type="select"
                helperText={
                  touched.announcementType ? errors.announcementType : ''
                }
                error={
                  touched.announcementType && Boolean(errors.announcementType)
                }
                onBlur={handleBlur}
                name="announcementType"
                value={values.announcementType}
                InputLabelProps={{
                  shrink: true
                }}
                onChange={handleChange}
                sx={{
                  marginRight: '20px',
                  width: '40%',
                  '& div': {
                    borderRadius: '12px'
                  }
                }}
                select
                menuItems={announcementTypesOptions}
              />
            )}

            <Box sx={{ marginTop: '40px' }}>
              <CustomTextField
                id="description"
                label="Description"
                required
                type="text"
                helperText={touched.description ? errors.description : ''}
                error={touched.description && Boolean(errors.description)}
                value={values.description}
                onBlur={handleBlur}
                onChange={handleChange}
                multiline={true}
                maxRows={15}
                name="description"
                minRows={10}
                sx={{
                  width: '56%'
                }}
              />
            </Box>
            {(values.announcementType === ANNOUNCEMENT_TYPES.video ||
              isTutorial) && (
              <Box sx={{ marginTop: '40px' }}>
                <CustomTextField
                  label="Add Youtube Link"
                  name="youtubeLink"
                  required
                  helperText={touched.youtubeLink ? errors.youtubeLink : ''}
                  error={touched.youtubeLink && Boolean(errors.youtubeLink)}
                  value={values.youtubeLink}
                  onBlur={handleBlur}
                  onChange={(e) => {
                    handleChange(e);
                    setIsUrlUploaded(false);
                  }}
                  type="text"
                  sx={{
                    width: '56%',
                    '& div': {
                      borderRadius: '12px'
                    },
                    marginRight: '14px'
                  }}
                />
                <CustomButton
                  title="Upload Link"
                  buttonType="primary"
                  variant="contained"
                  startIcon={<Upload />}
                  disabled={Boolean(errors.youtubeLink)}
                  onClick={() => uploadYouTubeLink(values.youtubeLink)}
                />
              </Box>
            )}
            {values.announcementType === ANNOUNCEMENT_TYPES.image && (
              <Box
                sx={{
                  marginTop: '40px',
                  width: '400px'
                }}>
                <CustomTextField
                  id="image"
                  label=""
                  required
                  type="file"
                  helperText={touched.img ? errors.img : ''}
                  error={touched.img && Boolean(errors.img)}
                  value={values.img}
                  onBlur={handleBlur}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    onImageChange(e);
                    setFieldValue('img', e.target.value);
                  }}
                  name="img"
                />

                <Typography
                  sx={{
                    color: 'rgb(102, 102, 102)',
                    fontSize: '12px',
                    marginTop: '10px'
                  }}>
                  *Upload only image file upto 5 MB
                </Typography>
                {selectedImg && (
                  <Box
                    sx={{ width: '400px', height: '160px', display: 'flex' }}>
                    <StyledImg src={selectedImg} alt="selected-img" />
                    <CancelOutlined
                      sx={{
                        position: 'relative',
                        top: '10px',
                        right: '14px',
                        color: '#a22e2a',
                        cursor: 'pointer',
                        fontSize: '30px'
                      }}
                      onClick={() => {
                        setSelectedImg(null);
                        setFieldValue('img', '');
                      }}
                    />
                  </Box>
                )}
              </Box>
            )}
            <Box
              sx={{
                marginTop: '80px',
                width: '90%',
                display: 'flex'
              }}>
              <Box
                sx={{
                  display: 'flex',
                  marginLeft: 'auto'
                }}>
                {/* <CustomButton
                title="Mobile Preview"
                buttonType="primary"
                variant="contained"
                startIcon={<RemoveRedEyeRounded />}
              /> */}
                <CustomButton
                  title="Cancel"
                  buttonType="secondary"
                  variant="contained"
                  onClick={() => history.go(-1)}
                />
                <CustomButton
                  title={isTutorial ? 'Add Tutorial' : 'Add Announcement'}
                  buttonType="primary"
                  variant="contained"
                  onClick={() => onSubmit(values)}
                  disabled={
                    !(
                      isValid &&
                      dirty &&
                      checkForUrlUpload(values.announcementType)
                    )
                  }
                />
              </Box>
            </Box>
          </Form>
        )}
      </Formik>
    </Box>
  );
};

const StyledImg = styled.img`
  width: 100%;
  height: 100%;
  border: 2px dashed rgba(0, 0, 0, 0.15);
  margin-top: 20px;
`;

export default InboxForm;
