import * as React from 'react';
import DashboardIcon from '@mui/icons-material/Dashboard';
import {
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Collapse
} from '@mui/material';
import MuiDrawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import { styled } from '@mui/material/styles';
import { useHistory, useLocation } from 'react-router-dom';
import {
  AUTH_STATUS,
  NURTURE_COLOR_GRADIENT,
  PARTNER_SUPERADMIN_ITEMS,
  ROUTES
} from 'src/constants';
import { UserAuthContext } from 'src/modules/Home';
import { DRAWER_WIDTH } from 'src/constants';
import { ExpandLess, ExpandMore } from '@mui/icons-material';

const StyledDrawer = styled(MuiDrawer)(({ theme }) => ({
  '& .MuiDrawer-paper': {
    position: 'relative',
    whiteSpace: 'nowrap',
    width: DRAWER_WIDTH,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    }),
    boxSizing: 'border-box',
    marginTop: '64px',
    paddingTop: '30px'
  }
}));

const Drawer = ({ userRoles }) => {
  const history = useHistory();
  const { authStatus } = React.useContext(UserAuthContext);
  const location = useLocation();
  const [isOpen, setIsOpen] = React.useState(false);
  const getNavItems = () => {
    let navItems = [
      {
        route: ROUTES.dashboard,
        icon: DashboardIcon,
        title: 'Dashboard'
      }
    ];
    if (userRoles.indexOf('partner_portal_superadmin') >= 0) {
      return [...navItems, ...PARTNER_SUPERADMIN_ITEMS];
    }
    const assignedTabs = PARTNER_SUPERADMIN_ITEMS.filter(({ permission }) =>
      userRoles.some((authority) => permission?.trim() == authority?.trim())
    );

    return [...navItems, ...assignedTabs];
  };

  const handleClick = () => {
    setIsOpen((prev) => !prev);
  };

  const createMenuItem = (item: any) => {
    let selected;
    selected =
      location.pathname.replace('/create', '').toLowerCase() ===
      item.route.toLowerCase();

    return (
      <ListItemButton
        onClick={() => {
          history.push(item.route);
        }}
        key={item.route}
        sx={{
          ...(selected && {
            background: NURTURE_COLOR_GRADIENT,
            color: '#ffffff'
          }),
          borderRadius: '10px',
          margin: '10px 10px 15px 10px'
        }}>
        <ListItemIcon sx={{ minWidth: '36px' }}>
          <item.icon
            sx={{
              color: '#929DC1',
              ...(selected && { color: '#ffffff' })
            }}
          />
        </ListItemIcon>
        <ListItemText
          sx={{
            color: '#929DC1',
            ...(selected && { color: '#ffffff' })
          }}
          primary={item.title}
        />
      </ListItemButton>
    );
  };

  return (
    <StyledDrawer variant="permanent">
      {authStatus === AUTH_STATUS.authenticated && (
        <List component="nav">
          {true &&
            getNavItems().map((item: any) => {
              if (item.children?.length > 0) {
                return (
                  <div key={item.route}>
                    <ListItemButton
                      onClick={handleClick}
                      key={item.route}
                      sx={{
                        borderRadius: '10px',
                        margin: '10px 10px 15px 10px'
                      }}>
                      <ListItemIcon sx={{ minWidth: '36px' }}>
                        {isOpen ? <ExpandLess /> : <ExpandMore />}
                      </ListItemIcon>
                      <ListItemText
                        sx={{
                          color: '#929DC1'
                        }}
                        primary={item.title}
                      />
                    </ListItemButton>
                    <Collapse in={isOpen} timeout="auto">
                      {item.children.map((child: any) => {
                        return (
                          <div
                            key={child.route}
                            style={{ marginLeft: `${child.depth * 20}px` }}>
                            {createMenuItem(child)}
                          </div>
                        );
                      })}
                    </Collapse>
                  </div>
                );
              }
              return createMenuItem(item);
            })}
        </List>
      )}
    </StyledDrawer>
  );
};;

export default Drawer;
