import { useOktaAuth } from '@okta/okta-react';
import { useState } from 'react';

const useOktaInstance = () => {
  const oktaData = useOktaAuth();
  const [loading, setLoading] = useState(false);
  const authToken = oktaData?.authState?.accessToken?.accessToken || '';
  const logout = async () => {
    try {
      setLoading(true);
      await oktaData?.oktaAuth?.signOut();
    } catch (err) {
      try {
        await oktaData?.oktaAuth?.signOut();
      } catch (err) {
        // empty
      } finally {
        // empty
      }
    } finally {
      setLoading(false);
      localStorage.clear();
    }
  };
  return { authToken, logout, loading };
};

export default useOktaInstance;
